import React, { useEffect } from "react"

// Components
import Header from "../components/header"
import Footer from "../components/footer"
import Background from "../components/background"
import Notification from "../components/notification"

export default function MainLayout(props) {
  useEffect(() => {
    var root = document.getElementsByTagName("html")[0]
    root.setAttribute(
      "class",
      "[&_*]:focus-visible:outline-none scroll-smooth scroll-pt-24 bg-wuiSlate-50 text-wuiSlate-500 fill-wuiSlate-500 selection:bg-wuiEmerald-500 selection:text-wuiEmerald-50"
    )
  }, [])

  return (
    <React.Fragment>
      <Header />
      <main>{props.children}</main>
      <Footer footerType={props.footerType} />
      <Background />
      <Notification />
    </React.Fragment>
  )
}

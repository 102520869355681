import React from "react"
import MainLayout from "../layouts/MainLayout"
import Seo from "../components/seo"
import DonationForm from "../components/donation/Form"
import Lottie from "../components/lottie"

// SEO Image
import ogImage from "../images/seo/support-page/support.png"

export default function Support() {
  const Heart = require("../images/lottie/heart.json")

  return (
    <MainLayout footerType="normal">
      <Seo
        title="Tailwind CSS Component Library | WindUI"
        ogtitle="Tailwind CSS Component Library | WindUI"
        description="Tailwind CSS Components Library - Streamline your Tailwind CSS projects with expertly made, responsive and accessible free UI Components in React and HTML."
        ogdescription="Tailwind CSS Components Library - Streamline your Tailwind CSS projects with expertly made, responsive and accessible free UI Components in React and HTML."
        ogimage={ogImage}
        url="/support"
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Accordions, Tailwind CSS Alerts, Tailwind CSS Badges, Tailwind CSS Buttons, Tailwind CSS Breadcrumbs, Tailwind CSS Cards, Tailwind CSS Checkboxes, Tailwind CSS Input Groups, Tailwind CSS Modals, Tailwind CSS Pagination, Tailwind CSS, Progress Bars, Tailwind CSS Radio Groups, Tailwind CSS Select Menus, Tailwind CSS Tables, Tailwind CSS Tabs, Tailwind CSS Tooltips, Tailwind CSS Toggles, Tailwind CSS Switches, Tailwind CSS Spinners"
        robots="index, follow"
        revisit_after="7 days"
      />

      <section className="pt-12 lg:py-12">
        <div className="container">
          {/* <!-- Component content --> */}
          <div className="grid grid-cols-4 items-center gap-6 pb-6 lg:grid-cols-12">
            <div className="relative col-span-4 lg:col-span-7">
              <h2 className="flex flex-wrap items-end justify-start gap-2 pb-6 text-4xl font-semibold leading-tight text-wuiSlate-900 md:text-5xl lg:text-6xl">
                Show your{" "}
                <span>
                  l
                  <span className="inline-block w-14 [&_svg]:inline-block [&_svg]:align-bottom">
                    <Lottie animationData={Heart} />
                  </span>
                  ve
                </span>
              </h2>
              <p className="pb-6 text-lg">
                We would like to introduce a new way for our community to get
                involved and support WindUI. We deeply value the support of our
                community members, and your generous contributions will play a
                vital role so we can continue to support and add to our free
                library.
              </p>
              <p className="pb-4 text-lg">
                With your contributions you will be able to support Wind UI's
              </p>
              <ul className="flex list-inside list-disc flex-col gap-1 text-slate-500">
                <li>
                  <strong className="font-medium text-slate-700">
                    Infrastructure:
                  </strong>{" "}
                  Ensuring WindUI stays online by covering our basic hosting and
                  maintenance.
                </li>
                <li>
                  <strong className="font-medium text-slate-700">
                    Support:
                  </strong>{" "}
                  Securing more time supporting and helping, you, our users.
                </li>
                <li>
                  <strong className="font-medium text-slate-700">
                    Development:
                  </strong>{" "}
                  WindUI will be able to keep adding to it's free component
                  library.
                </li>
              </ul>
            </div>
            <div className="relative col-span-4 w-full justify-self-start">
              <div className="relative z-10 flex flex-col items-center justify-center gap-4">
                <DonationForm />
                <a
                  href="https://www.paypal.com/donate/?hosted_button_id=6MGP8G8JELE3N"
                  target="_blank"
                  className="inline-flex h-10 w-full max-w-[25rem] items-center justify-center gap-2 whitespace-nowrap rounded bg-emerald-500 px-5 text-sm font-medium tracking-wide text-white transition duration-300 hover:bg-emerald-600 focus:bg-emerald-700 focus-visible:outline-none disabled:cursor-not-allowed disabled:border-emerald-300 disabled:bg-emerald-300 disabled:shadow-none"
                >
                  <span>Or support us through PayPal</span>
                  <span class="relative only:-mx-6">
                    <svg
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-5 w-5"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.67044 0.95002C3.68239 0.87122 3.72219 0.799312 3.78261 0.747332C3.84303 0.695353 3.92007 0.666742 3.99977 0.666687H8.64977C9.37966 0.666476 10.093 0.884105 10.6985 1.29171C11.304 1.69932 11.774 2.27836 12.0484 2.95469C11.843 2.89117 11.6294 2.85815 11.4144 2.85669H5.63977C5.55995 2.85658 5.48275 2.88512 5.42219 2.93712C5.36163 2.98911 5.32174 3.06111 5.30977 3.14002L3.91644 12.3334H2.3331C2.28512 12.3333 2.23771 12.3229 2.19412 12.3029C2.15052 12.2829 2.11176 12.2536 2.0805 12.2172C2.04923 12.1808 2.02619 12.1381 2.01294 12.092C1.9997 12.0459 1.99657 11.9975 2.00377 11.95L3.67044 0.95002ZM4.57577 12.6547L4.62444 12.3334V12.3307L5.29777 7.66669H9.29644C11.2218 7.66669 12.7711 6.12002 12.9471 4.30802C13.5934 4.75056 14.0809 5.38842 14.3384 6.12816C14.5958 6.86791 14.6096 7.67065 14.3778 8.4188C14.1459 9.16695 13.6805 9.8212 13.0499 10.2857C12.4192 10.7502 11.6564 11.0005 10.8731 11H8.17644L7.5531 15.0507C7.54101 15.1294 7.50115 15.2011 7.44074 15.253C7.38034 15.3048 7.30337 15.3333 7.22377 15.3334H4.5571C4.50907 15.3334 4.46158 15.3231 4.41791 15.3031C4.37423 15.2831 4.33539 15.2539 4.30405 15.2175C4.27271 15.1811 4.2496 15.1383 4.23632 15.0922C4.22304 15.046 4.2199 14.9975 4.2271 14.95L4.35044 14.14L4.57577 12.6547ZM12.2738 3.76669C12.2831 3.82602 12.2904 3.88535 12.2971 3.94469C12.3084 5.55402 10.9678 7.00002 9.29644 7.00002H5.3991L5.92577 3.52335H11.4144C11.6898 3.52335 11.9811 3.61269 12.2738 3.76669Z"
                        fill="currentColor"
                      />
                    </svg>
                  </span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </MainLayout>
  )
}

import React from "react"

export default function DonationForm() {
  const domationFormStyle = {
    maxHeight: 900,
    borderRadius: 8,
    minHeight: 520,
  }

  return (
    <div className="flex justify-center">
      <iframe
        id="donorboxIframe"
        src="https://donorbox.org/embed/wind-ui?default_interval=o&amount=3"
        name="donorbox"
        allowpaymentrequest="allowpaymentrequest"
        seamless="seamless"
        frameBorder="0"
        style={domationFormStyle}
        height="auto"
        width="400px"
      ></iframe>
      <script
        src="https://donorbox.org/widget.js"
        paypalExpress={true}
      ></script>
    </div>
  )
}
